export const Coins = ({ className }) => {
  return (
    <svg
      width="30"
      height="22"
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 0C11 0 15.3568 0 18.4814 1.4061C18.4814 1.4061 20.0472 2.11072 20.9428 3.06352C20.9428 3.06352 22 4.18829 22 5.5C22 5.5 22 6.81171 20.9428 7.93648C20.9428 7.93648 20.0473 8.88928 18.4814 9.5939C18.4814 9.5939 15.3568 11 11 11C11 11 6.64323 11 3.51857 9.5939C3.51857 9.5939 1.95275 8.88928 1.05719 7.93648C1.05719 7.93648 0 6.81171 0 5.5C0 5.5 0 4.18829 1.05719 3.06352C1.05719 3.06352 1.95275 2.11071 3.51857 1.4061C3.51857 1.4061 6.64323 0 11 0ZM11 2C11 2 7.0725 2 4.3393 3.22994C4.3393 3.22994 3.13703 3.77096 2.51451 4.43328C2.51451 4.43328 2 4.98067 2 5.5C2 5.5 2 6.01933 2.51451 6.56672C2.51451 6.56672 3.13703 7.22904 4.3393 7.77006C4.3393 7.77006 7.0725 9 11 9C11 9 14.9275 9 17.6607 7.77006C17.6607 7.77006 18.863 7.22904 19.4855 6.56672C19.4855 6.56672 20 6.01933 20 5.5C20 5.5 20 4.98067 19.4855 4.43328C19.4855 4.43328 18.863 3.77096 17.6607 3.22994C17.6607 3.22994 14.9275 2 11 2Z"
        fill="current"
      />
      <path
        d="M22 10.5V5.5C22 4.94772 21.5523 4.5 21 4.5C20.4477 4.5 20 4.94772 20 5.5V10.5C20 11.0203 19.4856 11.5679 19.4856 11.5679C18.8635 12.2301 17.6616 12.7709 17.6616 12.7709C14.9296 14 11 14 11 14C7.07036 14 4.33842 12.7709 4.33842 12.7709C3.13653 12.2301 2.51438 11.5679 2.51438 11.5679C2 11.0203 2 10.5 2 10.5V5.5C2 4.94771 1.55229 4.5 1 4.5C0.447716 4.5 0 4.94771 0 5.5V10.5C3.57628e-07 11.8124 1.05671 12.9372 1.05671 12.9372C1.95206 13.8903 3.51783 14.5948 3.51783 14.5948C6.64117 16 11 16 11 16C15.3588 16 18.4822 14.5948 18.4822 14.5948C20.0479 13.8903 20.9433 12.9372 20.9433 12.9372C22 11.8124 22 10.5 22 10.5Z"
        fill="current"
      />
      <path
        d="M6 9.625V14.625C6 15.1773 6.44772 15.625 7 15.625C7.55228 15.625 8 15.1773 8 14.625V9.625C8 9.07271 7.55228 8.625 7 8.625C6.44772 8.625 6 9.07271 6 9.625Z"
        fill="current"
      />
      <path
        d="M21.0956 6.0921C24.7526 6.43318 27.189 7.75919 27.189 7.75919C30 9.28912 30 11.5 30 11.5C30 12.8124 28.9433 13.9373 28.9433 13.9373C28.0479 14.8903 26.4822 15.5948 26.4822 15.5948C23.3588 17 19 17 19 17C15.1903 17 12.2152 15.8878 12.2152 15.8878C12.2138 15.8873 12.2125 15.8868 12.2125 15.8868C11.9641 15.7938 11.7624 15.6059 11.6525 15.3645C11.598 15.245 11.5677 15.116 11.5631 14.9847C11.5627 14.9732 11.5625 14.9616 11.5625 14.95C11.5625 14.8304 11.584 14.7117 11.6259 14.5996C11.7696 14.2154 12.1334 13.958 12.5435 13.9502L12.5625 13.95C12.6821 13.95 12.8008 13.9715 12.9129 14.0134C15.5502 15 19 15 19 15C22.9296 15 25.6616 13.7709 25.6616 13.7709C26.8635 13.2301 27.4856 12.5679 27.4856 12.5679C28 12.0203 28 11.5 28 11.5C28 10.4776 26.2329 9.51586 26.2329 9.51586C24.1568 8.38591 20.9073 8.08321 20.9073 8.08321C20.3931 8.03532 20 7.60388 20 7.08752C20 7.05753 20.0014 7.02755 20.004 6.99767L20.0043 6.99478C20.0259 6.76261 20.128 6.5453 20.2929 6.38042C20.3148 6.35852 20.3377 6.33765 20.3615 6.31788C20.541 6.16901 20.7668 6.08752 21 6.08752C21.03 6.08752 21.06 6.08887 21.0899 6.09157L21.0927 6.09184L21.0956 6.0921Z"
        fill="current"
      />
      <path
        d="M30 16.5V11.5C30 10.9477 29.5523 10.5 29 10.5C28.4477 10.5 28 10.9477 28 11.5V16.5C28 17.0203 27.4856 17.5679 27.4856 17.5679C26.8635 18.2301 25.6616 18.7709 25.6616 18.7709C22.9296 20 19 20 19 20C15.0704 20 12.3384 18.7709 12.3384 18.7709C11.1365 18.2301 10.5144 17.5679 10.5144 17.5679C10 17.0203 10 16.5 10 16.5L10 14.9125C10 14.3602 9.55229 13.9125 9 13.9125C8.44772 13.9125 8 14.3602 8 14.9125L8 16.5C8 17.8124 9.05671 18.9372 9.05671 18.9372C9.95205 19.8903 11.5178 20.5948 11.5178 20.5948C14.6412 22 19 22 19 22C23.3588 22 26.4822 20.5948 26.4822 20.5948C28.0479 19.8903 28.9433 18.9372 28.9433 18.9372C30 17.8124 30 16.5 30 16.5Z"
        fill="current"
      />
      <path
        d="M22 15.625V20.625C22 21.1773 22.4477 21.625 23 21.625C23.5523 21.625 24 21.1773 24 20.625V15.625C24 15.0727 23.5523 14.625 23 14.625C22.4477 14.625 22 15.0727 22 15.625Z"
        fill="current"
      />
      <path
        d="M14 9.625V20.625C14 21.1773 14.4477 21.625 15 21.625C15.5523 21.625 16 21.1773 16 20.625V9.625C16 9.07271 15.5523 8.625 15 8.625C14.4477 8.625 14 9.07271 14 9.625Z"
        fill="current"
      />
    </svg>
  );
};
