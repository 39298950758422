export const Textarea = ({
  label,
  value = "",
  onChange,
  id,
  className,
  limit,
}) => {
  return (
    <div className={["relative", className].filter(Boolean).join(" ")}>
      <label
        className="absolute top-0.5 left-0.5 right-0.5 font-semibold text-main break-words cursor-default pointer-events-none text-xs px-[12px] pt-[4px] z-10 bg-white"
        forhtml={id}
        id={`${id}-label`}
      >
        {label}
      </label>
      <div className="relative">
        <textarea
          className="appearance-none h-auto resize-none box-border text-[14px] md:text-[20px] w-full text-black block text-left px-[12px] pt-[18px] border-2 border-line bg-white min-h-[60px] focus:border-main focus:outline-none"
          id={id}
          rows="3"
          aria-invalid="false"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        {limit && (
          <div className="absolute bottom-1 right-1 text-fourth text-sm">
            {value?.length}/{limit}
          </div>
        )}
      </div>
    </div>
  );
};
