import { useCallback, useEffect, useRef, useState } from "react";
import { useErrorAddPlatformModal } from "components/Modal/ErrorAddPlatformModal";
import { META_AUTH_LINK } from "constants";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { EVENTS, eventEmitter } from "utils";
import { toast } from "react-toastify";

export const useFacebook = ({ onSuccess, checkAuth = false }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const isActivating = useRef(false);
  const queryParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const [facebookIsLoading, setFacebookIsLoading] = useState(false);
  const [instagramIsLoading, setInstagramIsLoading] = useState(false);
  const { showErrorReasonModal } = useErrorAddPlatformModal();

  const code = queryParams.get("code");
  const error = queryParams.get("error");
  const authPlatform = window.localStorage.getItem("platform-auth-process");

  const onFinishAuth = useCallback(() => {
    window.localStorage.setItem("platform-auth-process", "false");
    setInstagramIsLoading(false);
    setFacebookIsLoading(false);
  }, []);

  const getFacebookPages = useCallback(
    (metaId) => {
      fetch(`/api/private/platform/facebook/queryPages?metaId=${metaId}`)
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            eventEmitter.emit(EVENTS.FACEBOOK_PAGES_MODAL, {
              data: data.data,
              metaId,
            });
          } else {
            toast.error(t(data.message || "be-error-default"));
          }
          setFacebookIsLoading(false);
        });
    },
    [t]
  );

  const getInstagramAccounts = useCallback(
    (metaId) => {
      fetch(`/api/private/platform/instagram/queryAccounts?metaId=${metaId}`)
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            eventEmitter.emit(EVENTS.INSTAGRAM_ACCOUNTS_MODAL, {
              data: data.data,
              metaId,
            });
          } else {
            toast.error(t(data.message || "be-error-default"));
          }
          setInstagramIsLoading(false);
        });
    },
    [t]
  );

  const onAccount = useCallback(
    (code, platform) => {
      fetch(`/api/private/platform/meta/addUser?code=${code}`)
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            if (platform === "Facebook") {
              getFacebookPages(data.data);
            } else if (platform === "Instagram") {
              getInstagramAccounts(data.data);
            }
          } else {
            showErrorReasonModal({ platform: "Meta" });
            toast.error(t(data.message || "be-error-default"));
          }
          onFinishAuth();
          navigate(`/post`);
        })
        .catch(() => onFinishAuth());
    },
    [t, getFacebookPages, getInstagramAccounts, onFinishAuth]
  );

  const facebookAuth = useCallback(() => {
    setFacebookIsLoading(true);
    window.localStorage.setItem("platform-auth-process", "Facebook");
    fetch(`/api/private/platform/meta/authLink`)
      .then((data) => data.json())
      .then((data) => {
        if (data.success) {
          if (data.data === "REDIRECT") {
            window.location.replace(META_AUTH_LINK);
          } else {
            getFacebookPages(data.data);
            window.localStorage.removeItem("platform-auth-process");
          }
        } else {
          toast.error(t(data.message || "be-error-default"));
          onFinishAuth();
        }
      });
  }, [t, getFacebookPages, onFinishAuth]);

  const instagramAuth = useCallback(() => {
    setInstagramIsLoading(true);
    window.localStorage.setItem("platform-auth-process", "Instagram");
    fetch(`/api/private/platform/meta/authLink`)
      .then((data) => data.json())
      .then((data) => {
        if (data.success) {
          if (data.data === "REDIRECT") {
            window.location.replace(META_AUTH_LINK);
          } else {
            getInstagramAccounts(data.data);
            window.localStorage.removeItem("platform-auth-process");
          }
        } else {
          toast.error(t(data.message || "be-error-default"));
          onFinishAuth();
        }
      })
      .catch(() => onFinishAuth());
  }, [t, getInstagramAccounts, onFinishAuth]);

  // useEffect(() => {
  //   if (
  //     checkAuth &&
  //     code &&
  //     !isActivating.current &&
  //     (authPlatform === "Instagram" || authPlatform === "Facebook")
  //   ) {
  //     isActivating.current = true;
  //     if (authPlatform === "Instagram") {
  //       setInstagramIsLoading(true);
  //     } else if (authPlatform === "Facebook") {
  //       setFacebookIsLoading(true);
  //     }
  //     onAccount(code, authPlatform);
  //   }
  // }, [code, checkAuth, authPlatform]);

  const onCheck = useCallback(() => {
    const authPlatform = window.localStorage.getItem("platform-auth-process");
    if (authPlatform === "Instagram") {
      setInstagramIsLoading(true);
      isActivating.current = true;
      onAccount(code, authPlatform);
    } else if (authPlatform === "Facebook") {
      setFacebookIsLoading(true);
      isActivating.current = true;
      onAccount(code, authPlatform);
    }
  }, [code, onAccount]);

  useEffect(() => {
    if (checkAuth && code && !isActivating.current) onCheck();
  }, [checkAuth, code, !isActivating.current, onCheck]);

  useEffect(() => {
    if (checkAuth && error && !isActivating.current) {
      // Decline auth
      // ?error=access_denied&error_code=200&error_description=Permissions+error&error_reason=user_denied#_=_
      navigate("/post");
    }
  }, [code, checkAuth, navigate]);

  const addFacebook = useCallback(
    (facebookId, metaId) => {
      fetch(
        `/api/private/platform/facebook/add?facebookId=${facebookId}&metaId=${metaId}`
      )
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            onSuccess();
          } else {
            toast.error(t(data.message || "be-error-default"));
          }
        });
    },
    [t, onSuccess]
  );

  const removeFacebook = useCallback(
    (platformId) => {
      fetch(`/api/private/platform/facebook/remove`, {
        method: "POST",
        body: JSON.stringify({ platformId }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            onSuccess();
          } else {
            toast.error(t(data.message || "be-error-default"));
          }
        });
    },
    [t, onSuccess]
  );

  const addInstagram = useCallback(
    (instagramId, metaId) => {
      fetch(
        `/api/private/platform/instagram/add?instagramId=${instagramId}&metaId=${metaId}`
      )
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            onSuccess();
          } else {
            toast.error(t(data.message || "be-error-default"));
          }
        });
    },
    [t, onSuccess]
  );

  const removeInstagram = useCallback(
    (platformId) => {
      fetch(`/api/private/platform/instagram/remove`, {
        method: "POST",
        body: JSON.stringify({ platformId }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            onSuccess();
          } else {
            toast.error(t(data.message || "be-error-default"));
          }
        });
    },
    [t, onSuccess]
  );

  return {
    facebookAuth,
    removeFacebook,
    addFacebook,
    instagramAuth,
    addInstagram,
    removeInstagram,
    facebookIsLoading,
    instagramIsLoading,
  };
};
