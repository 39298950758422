import React from "react";
import { usePagination } from "hooks/usePagination";
import { Button } from "./Button";

const DOTS = "...";

export const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul className="flex gap-2">
      {currentPage !== 1 ? (
        <div
          className="h-10 w-10 flex justify-center items-center cursor-pointer"
          onClick={onPrevious}
        >
          {`<`}
        </div>
      ) : null}
      {paginationRange.map((pageNumber, idx) => {
        if (pageNumber === DOTS) {
          return (
            <li key={"dots" + idx} className="mt-[10px]">
              &#8230;
            </li>
          );
        }

        return (
          <div
            key={pageNumber}
            className={[
              "h-10 w-10 flex justify-center items-center",
              pageNumber === currentPage
                ? "bg-main text-acent"
                : "cursor-pointer",
            ]
              .filter(Boolean)
              .join(" ")}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </div>
        );
      })}
      {currentPage !== lastPage ? (
        <div
          className="h-10 w-10 flex justify-center items-center cursor-pointer"
          onClick={onNext}
        >
          {`>`}
        </div>
      ) : null}
    </ul>
  );
};
