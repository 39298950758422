import QueryClient from "./QueryClient";
import { GoogleProvider } from "./GoogleProvider";
import { I18nProvider } from "./I18nProvider";
import { ErrorAddPlatformProvider } from "components/Modal/ErrorAddPlatformModal";

export const Providers = ({ children }) => {
  return (
    <I18nProvider>
      <QueryClient>
        <GoogleProvider>
          <ErrorAddPlatformProvider>{children}</ErrorAddPlatformProvider>
        </GoogleProvider>
      </QueryClient>
    </I18nProvider>
  );
};
