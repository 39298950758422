import React from "react";

export const Facebook = ({ className, size = 500 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <g id="surface1">
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(2.69928%,39.99939%,100%)",
            fillOpacity: 1,
          }}
          d="M 500 250 C 500 111.929688 388.070313 0 250 0 C 111.929688 0 0 111.929688 0 250 C 0 367.246094 80.714844 465.621094 189.605469 492.636719 L 189.605469 326.394531 L 138.054688 326.394531 L 138.054688 250 L 189.605469 250 L 189.605469 217.082031 C 189.605469 131.988281 228.113281 92.550781 311.652344 92.550781 C 327.492188 92.550781 354.820313 95.652344 366 98.761719 L 366 168.015625 C 360.101563 167.394531 349.851563 167.082031 337.117188 167.082031 C 296.125 167.082031 280.285156 182.609375 280.285156 222.980469 L 280.285156 250 L 361.945313 250 L 347.917969 326.394531 L 280.285156 326.394531 L 280.285156 498.167969 C 404.074219 483.21875 500 377.816406 500 250 "
        />
        <path
          style={{
            stroke: "none",
            fillRule: "nonzero",
            fill: "rgb(100%,100%,100%)",
            fillOpacity: 1,
          }}
          d="M 347.917969 326.394531 L 361.945313 250 L 280.289063 250 L 280.289063 222.980469 C 280.289063 182.609375 296.125 167.082031 337.117188 167.082031 C 349.851563 167.082031 360.101563 167.390625 366 168.011719 L 366 98.761719 C 354.820313 95.652344 327.492188 92.546875 311.652344 92.546875 C 228.113281 92.546875 189.605469 131.988281 189.605469 217.082031 L 189.605469 250 L 138.054688 250 L 138.054688 326.394531 L 189.605469 326.394531 L 189.605469 492.636719 C 208.949219 497.4375 229.171875 500 250 500 C 260.253906 500 270.355469 499.367188 280.289063 498.167969 L 280.289063 326.394531 "
        />
      </g>
    </svg>
  );
};
