export const Button = ({ isLoading, children, ...restProps }) => {
  return (
    <button
      {...restProps}
      className={[
        "px-[18px] h-[60px] inline-block font-semibold relative text-lg select-none cursor-pointer bg-main text-white transition-all duration-150 focus-visible:outline-none disabled:bg-gray-500 disabled:cursor-not-allowed",
        restProps.className,
      ]
        .filter(Boolean)
        .join(" ")}
    >
      {isLoading ? (
        <div className="flex justify-center items-center">
          <div className="w-5 h-5 rounded-full animate-spin border-4 border-solid border-white border-t-transparent mr-2" />
          {children}
        </div>
      ) : (
        children
      )}
    </button>
  );
};
