export const Plus = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M1 13H23C23.5523 13 24 12.5523 24 12C24 11.4477 23.5523 11 23 11H1C0.447715 11 0 11.4477 0 12C0 12.5523 0.447715 13 1 13Z"
        fill="current"
      />
      <path
        d="M11 1V23C11 23.5523 11.4477 24 12 24C12.5523 24 13 23.5523 13 23V1C13 0.447715 12.5523 0 12 0C11.4477 0 11 0.447715 11 1Z"
        fill="current"
      />
    </svg>
  );
};
