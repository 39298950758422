import { useMemo } from "react";
import { Platform } from "./Platform";
import { usePlatforms } from "hooks/dev/usePlatforms";
import { FacebookAuthModal } from "components/Modal/FacebookAuthModal";
import { InstagramAuthModal } from "components/Modal/InstagramAuthModal";

export const Platforms = ({ selectedPlatforms, onChangeSelectedPlatforms }) => {
  const { platforms, isLoading, refetchPlatforms } = usePlatforms({
    checkAuth: true,
  });

  const list = useMemo(() => {
    const result = [];

    Object.keys(platforms || {}).forEach((platform) => {
      const platformInfo = platforms[platform];

      if (platformInfo.users.length) {
        const platformInfoWithoutUsers = { ...platformInfo };
        delete platformInfoWithoutUsers.users;
        platformInfo.users.forEach((user) => {
          result.push({
            ...platformInfoWithoutUsers,
            ...user,
            status: user.status || "CONNECTED", // move status to BE
          });
        });
      } else {
        result.push({
          ...platformInfo,
        });
      }
    });

    return result;
  }, [platforms]);

  if (isLoading) return <div>loading</div>;

  return (
    <>
      <div className="flex gap-3">
        {list.map((platform, idx) => (
          <Platform
            key={idx}
            {...platform}
            onAuth={platform.onAuth}
            selectedPlatforms={selectedPlatforms}
            onChangeSelectedPlatforms={onChangeSelectedPlatforms}
          />
        ))}
      </div>
      <FacebookAuthModal onSuccess={refetchPlatforms} />
      <InstagramAuthModal onSuccess={refetchPlatforms} />
    </>
  );
};
