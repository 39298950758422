import { Dialog } from "@headlessui/react";

export function PreviewModal({ isOpen, onClose, children }) {
  return (
    <Dialog
      as="div"
      className="relative"
      onClose={onClose}
      open={isOpen}
      unmount={false}
    >
      <div
        className="fixed inset-0 bg-black bg-opacity-25 z-50"
        onClick={onClose}
      />
      <div className="fixed inset-0 overflow-y-auto z-50">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel className="w-auto transform overflow-hidden bg-third text-left align-middle shadow-xl transition-all border border-main">
            {children}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
