export const Video = ({ className }) => {
  return (
    <svg
      width="23"
      height="26"
      viewBox="0 0 23 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M15 9H22C22.5523 9 23 8.55228 23 8C23 7.44772 22.5523 7 22 7H16V1C16 0.447715 15.5523 0 15 0C14.4477 0 14 0.447715 14 1V8C14 8.55228 14.4477 9 15 9Z"
        fill="current"
      />
      <path
        d="M1 2V13C1 13.5523 1.44772 14 2 14C2.55228 14 3 13.5523 3 13V2H14.5858L21 8.41421V24H18C17.4477 24 17 24.4477 17 25C17 25.5523 17.4477 26 18 26H21C21.8284 26 22.4142 25.4142 22.4142 25.4142C23 24.8284 23 24 23 24V8C23 7.73478 22.8946 7.48043 22.7071 7.29289L15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0H3C2.17157 0 1.58579 0.585787 1.58579 0.585787C1 1.17157 1 2 1 2Z"
        fill="current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 18C0 18 0 17.1716 0.585787 16.5858C0.585787 16.5858 1.17157 16 2 16H9C9 16 9.82843 16 10.4142 16.5858C10.4142 16.5858 11 17.1716 11 18V23C11 23 11 23.8284 10.4142 24.4142C10.4142 24.4142 9.82843 25 9 25H2C2 25 1.17157 25 0.585787 24.4142C0.585787 24.4142 0 23.8284 0 23V18ZM2 18V23H9V18H2Z"
        fill="current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5528 16.6056L9.55291 18.6055C9.214 18.775 9 19.1212 9 19.5C9 19.5123 9.00023 19.5245 9.00068 19.5368C9.00593 19.6795 9.0417 19.8195 9.10557 19.9472C9.22418 20.1844 9.43217 20.3648 9.68377 20.4487C9.78561 20.4826 9.89224 20.5 9.99959 20.5L9.97634 20.5003C9.88672 20.5024 9.79779 20.5166 9.71194 20.5424C9.45796 20.6188 9.24474 20.7929 9.11918 21.0266C9.04095 21.1721 9 21.3348 9 21.5C9 21.5286 9.00123 21.5572 9.00368 21.5857C9.03262 21.922 9.22925 22.221 9.52656 22.3808L13.5266 24.5308C14.013 24.7923 14.6193 24.6099 14.8808 24.1234C14.9591 23.9779 15 23.8152 15 23.65V17.5C15 16.9477 14.5523 16.5 14 16.5C13.8448 16.5 13.6916 16.5361 13.5528 16.6056ZM10.4734 20.6192L13 21.9772V19.118L10.4472 20.3944C10.3148 20.4606 10.1693 20.4966 10.0212 20.4998L10.0007 20.5C10.1657 20.5001 10.3281 20.5411 10.4734 20.6192Z"
        fill="current"
      />
    </svg>
  );
};
