import { useErrorAddPlatformModal } from "components/Modal/ErrorAddPlatformModal";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const useYouTube = ({ onSuccess, checkAuth = false }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const isActivating = useRef(false);
  const queryParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const { showErrorReasonModal } = useErrorAddPlatformModal();

  const auth = queryParams.get("auth");
  const code = queryParams.get("code");

  const onFinishAuth = useCallback(() => {
    window.localStorage.setItem("platform-auth-process", "false");
    setIsLoading(false);
  }, []);

  const onAccount = useCallback(
    (code) => {
      fetch(`/api/private/platform/youtube/addUser?code=${code}`)
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            onSuccess();
          } else {
            showErrorReasonModal({ platform: "YouTube" });
            toast.error(t(data.message || "be-error-default"));
          }
          onFinishAuth();
          navigate(`/post`);
        })
        .catch(() => onFinishAuth());
    },
    [t, onSuccess, navigate, onFinishAuth]
  );

  const onCheck = useCallback(() => {
    const authPlatform = window.localStorage.getItem("platform-auth-process");
    if (authPlatform === "YouTube") {
      setIsLoading(true);
      isActivating.current = true;
      onAccount(code);
    }
  }, [checkAuth, code]);

  useEffect(() => {
    if (checkAuth && !isActivating.current) onCheck();
  }, [checkAuth, !isActivating.current, onCheck]);

  const onAuth = useCallback(() => {
    setIsLoading(true);
    window.localStorage.setItem("platform-auth-process", "YouTube");
    fetch(`/api/private/platform/youtube/getAuthLink`)
      .then((data) => data.json())
      .then((data) => {
        if (data.success) {
          window.location.replace(data.data);
        } else {
          onFinishAuth();
          toast.error(t(data.message || "be-error-default"));
        }
      })
      .catch(() => onFinishAuth());
  }, [t, onFinishAuth]);

  const onRemove = useCallback(
    (platformId) => {
      fetch(`/api/private/platform/youtube/removeUser`, {
        method: "POST",
        body: JSON.stringify({ platformId }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.success) {
            onSuccess();
          } else {
            toast.error(t(data.message || "be-error-default"));
          }
        });
    },
    [t, onSuccess]
  );

  return { onAuth, onRemove, youtubeIsLoading: isLoading };
};
