export const TikTok = ({ className, size = 48 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <g clipPath="url(#clip0_205_329)">
        <rect width="48" height="48" fill="black" />
        <path
          d="M20.8983 20.6006V19.3548C20.4753 19.2978 20.0453 19.2622 19.6012 19.2622C14.3071 19.2552 9.99995 23.6048 9.99995 28.9583C9.99995 32.2402 11.6213 35.1448 14.1027 36.9032C12.5025 35.1733 11.5226 32.8453 11.5226 30.2967C11.5226 25.0215 15.71 20.7216 20.8983 20.6006Z"
          fill="#25F4EE"
        />
        <path
          d="M21.1307 34.7248C23.4923 34.7248 25.4238 32.824 25.5084 30.4605L25.5155 9.33838H29.3362C29.2587 8.90411 29.2164 8.45562 29.2164 8H23.9998L23.9928 29.1221C23.9082 31.4857 21.9766 33.3864 19.6151 33.3864C18.882 33.3864 18.1912 33.2013 17.5779 32.8738C18.3674 33.9915 19.6645 34.7248 21.1307 34.7248Z"
          fill="#25F4EE"
        />
        <path
          d="M36.4777 16.5072V15.3326C35.0185 15.3326 33.665 14.8983 32.523 14.1437C33.5381 15.3184 34.9127 16.1727 36.4777 16.5072Z"
          fill="#25F4EE"
        />
        <path
          d="M32.5229 14.1437C31.409 12.8552 30.7394 11.1751 30.7394 9.33838H29.3436C29.7031 11.3459 30.8875 13.0688 32.5229 14.1437Z"
          fill="#FE2C55"
        />
        <path
          d="M19.6082 24.5232C17.1903 24.5232 15.2235 26.5094 15.2235 28.9512C15.2235 30.6527 16.1822 32.1334 17.578 32.8738C17.0564 32.1477 16.7462 31.2578 16.7462 30.2896C16.7462 27.8478 18.713 25.8615 21.1309 25.8615C21.5821 25.8615 22.0191 25.9398 22.428 26.068V20.6861C22.005 20.6291 21.575 20.5934 21.1309 20.5934C21.0534 20.5934 20.9829 20.6006 20.9053 20.6006V24.7296C20.4894 24.6014 20.0594 24.5232 19.6082 24.5232Z"
          fill="#FE2C55"
        />
        <path
          d="M36.4773 16.5072V20.6006C33.7703 20.6006 31.2607 19.725 29.2163 18.2442V28.9584C29.2163 34.3048 24.9092 38.6616 19.6081 38.6616C17.5637 38.6616 15.6604 38.0066 14.1025 36.9032C15.8578 38.8039 18.3603 40 21.1308 40C26.4248 40 30.739 35.6503 30.739 30.2967V19.5826C32.7833 21.0634 35.2929 21.939 37.9999 21.939V16.671C37.4712 16.671 36.9636 16.614 36.4773 16.5072Z"
          fill="#FE2C55"
        />
        <path
          d="M29.2163 28.9584V18.2442C31.2607 19.725 33.7702 20.6006 36.4772 20.6006V16.5072C34.9122 16.1727 33.5376 15.3184 32.5225 14.1437C30.887 13.0688 29.7098 11.3459 29.3361 9.33838H25.5154L25.5083 30.4604C25.4238 32.8239 23.4922 34.7248 21.1307 34.7248C19.6644 34.7248 18.3744 33.9915 17.5708 32.8809C16.175 32.1334 15.2163 30.6599 15.2163 28.9584C15.2163 26.5166 17.183 24.5303 19.601 24.5303C20.0521 24.5303 20.4892 24.6086 20.8981 24.7368V20.6077C15.7097 20.7216 11.5224 25.0215 11.5224 30.2967C11.5224 32.8453 12.5022 35.1662 14.1025 36.9032C15.6604 38.0066 17.5637 38.6616 19.608 38.6616C24.9021 38.6616 29.2163 34.3047 29.2163 28.9584Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_205_329">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
