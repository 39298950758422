import React, { createContext, useContext, useState } from "react";
import { Button, Input, Loading, Modal } from "components/common";
import { useTranslation } from "react-i18next";

const submitReasonFn = ({ platform, reasonCode, reasonText }) =>
  fetch(`/api/private/platform/errorAddPlatformReason`, {
    method: "POST",
    body: JSON.stringify({ platform, reasonCode, reasonText }),
    headers: {
      "Content-Type": "application/json",
    },
  });

const ModalContext = createContext();

const ModalDialog = ({ data, isOpen, onClose }) => {
  const { t } = useTranslation();
  const [reasonCode, setReasonCode] = useState(5);
  const [reasonText, setReasonText] = useState("");
  const { platform } = data;

  const reasons = [
    { code: 1, trans: t("reason-i-dont-have-acc", "I don't have account") },
    {
      code: 2,
      trans: t(
        "reason-there-were-auth-problem",
        "There were problems with the authentication process"
      ),
    },
    {
      code: 3,
      trans: t("reason-i-dont-have-permission", "I don't have persmission"),
    },
    { code: 4, trans: t("reason-i-cahnged-my-mind", "I changed my mind") },
    { code: 5, trans: t("reason-othre", "Other") },
  ];

  const isDisabled = reasonCode === 5 && !reasonText;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setReasonCode(5);
        setReasonText("");
        onClose();
      }}
      title={t("failed-to-add-platform", "Failed to Add Platform")}
    >
      <div className="pt-[20px]">
        {reasons.map((reason, index) => (
          <div key={index} className="mb-4 cursor-pointer">
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="radio"
                className="form-radio h-5 w-5 text-main cursor-pointer outline-none"
                value={reason.code}
                checked={reasonCode === reason.code}
                onChange={(e) => setReasonCode(reason.code)}
              />
              <span className="ml-2 text-gray-700">{reason.trans}</span>
            </label>
          </div>
        ))}
        {reasonCode === 5 && (
          <div className="pb-[10px]">
            <Input
              label={t("cusotom-reason", "Custom Reason")}
              maxLength={100}
              value={reasonText}
              onChange={setReasonText}
            />
          </div>
        )}
        <Button
          className="w-full"
          disabled={isDisabled}
          onClick={() => {
            submitReasonFn({ platform, reasonCode, reasonText });
            setReasonCode(5);
            setReasonText("");
            onClose();
          }}
        >
          {t("submit", "Submit")}
        </Button>
      </div>
    </Modal>
  );
};

export const ErrorAddPlatformProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const showErrorReasonModal = (content) => {
    setData(content);
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
    setData(null);
  };

  return (
    <ModalContext.Provider value={{ showErrorReasonModal }}>
      {children}
      {isOpen && (
        <ModalDialog data={data} isOpen={isOpen} onClose={hideModal} />
      )}
    </ModalContext.Provider>
  );
};

// Custom hook to use the modal context
export const useErrorAddPlatformModal = () => {
  return useContext(ModalContext);
};
