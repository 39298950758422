import { localesMap } from "constants";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export const useCombineTranslation = (prefix, namespace) => {
  const { t, i18n } = useTranslation(namespace);
  const getI18n = useCallback(
    (key, options = {}) => {
      const i18nKey = [prefix, key].filter((v) => v).join("-");
      return t(i18nKey, options).trim() || options.defaultValue;
    },
    [t, prefix]
  );
  return { i18n, t, getI18n };
};

export const useLanguage = () => useCombineTranslation("", "app");

export const useLng = () => {
  const { i18n } = useTranslation();
  const locale = i18n.language || "en";
  return { locale, language: localesMap?.[locale]?.name };
};
