import { Menu, MobileMenu } from "./Menu";
import { ScrollRestoration } from "react-router-dom";

export const Layout = ({ children }) => {
  return (
    <>
      <div className="min-h-[calc(100dvh)] flex flex-col">
        <div className="flex-grow flex">
          <div className="hidden lg:flex">
            <Menu />
          </div>
          {children}
        </div>
        <div className="lg:hidden sticky bottom-0 z-10">
          <MobileMenu />
        </div>
      </div>
      <ScrollRestoration />
    </>
  );
};
