import { useTranslation } from "react-i18next";
import { useUser } from "hooks/dev";
import { Layout } from "components/common/Layout";
import { Button, Container } from "components/common";
import { useQuery } from "@tanstack/react-query";
import { CheckCircle } from "components/icons";
import { PAYMENT_LINK } from "constants";

const freeOptions = [
  { title: { key: "Facebook", defaultValue: "" } },
  { title: { key: "Instagram Reels", defaultValue: "" } },
  { title: { key: "X", defaultValue: "" } },
  { title: { key: "YouTube", defaultValue: "" } },
  { title: { key: "TikTok", defaultValue: "" } },
];

const fn = () =>
  fetch(`/api/private/user/payment`).then((response) => response.json());

export const SubscriptionComponent = () => {
  const { t } = useTranslation();
  const { userInfo } = useUser();
  const { userId } = userInfo || {};

  return (
    <div>
      <div className="flex md:flex-row flex-col gap-4 md:gap-5 mb-5 max-w-[1000px]">
        <div className="md:basis-1/2 p-4 flex flex-col justify-between text-main border border-main">
          <div>
            <h4 className="mb-1 text-2xl font-semibold flex items-center">
              {t("subscription", { defaultValue: "Subscription" })}
              <div className="text-xs border border-main bg-main text-acent py-0.5 px-2 ml-2 rounded-xl">
                {t("expert", {
                  defaultValue: "Expert",
                })}
              </div>
            </h4>
            <div className="mb-5">
              {t("pricing-subscription-desc", {
                defaultValue: "For professional influencers",
              })}
            </div>
            <div className="mb-7 text-primary-gray">
              {freeOptions.map((el, idx) => (
                <div className="flex gap-3 mb-2 items-center" key={idx}>
                  <CheckCircle className="w-4 h-4" />
                  <div>{el.title.key}</div>
                </div>
              ))}
              <div className="flex gap-3 mb-2 items-center">
                <CheckCircle className="w-4 h-4" />
                <div className="font-bold text-main">
                  {t("unlimited", { defaultValue: "Unlimited" })}
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-4">
            {PAYMENT_LINK.map((el, idx) => (
              <a
                key={idx}
                className="w-full relative"
                href={`${el.link}?client_reference_id=${userId}`}
              >
                <Button className="w-full">
                  <div className="text-lg text-acent">
                    {el.price}$/
                    {el.month === 12
                      ? t("year", { defaultValue: "year" })
                      : t("month", { defaultValue: "month" })}
                  </div>
                  <div className="text-xs">
                    {t("subscribe", { defaultValue: "Subscribe" })}
                  </div>
                </Button>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Subscription = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery(["GET_PAYMENTS"], () => fn());
  const { userInfo } = useUser();
  const { userId } = userInfo || {};

  const { activated, list } = data?.data || { activated: false, list: [] };

  return (
    <Layout>
      <Container>
        <div className="pt-[10px] w-full h-full flex flex-col">
          <div className="text-3xl text-main mb-4 font-bold">
            {t("subscription", { defaultValue: "Subscription" })}
          </div>
          {!isLoading && !activated && <SubscriptionComponent />}
          {!isLoading && activated && (
            <div className="text-xl text-main mb-4 font-bold">
              {t("status", { defaultValue: "Status" })}:{" "}
              <span className="text-green-600">
                {t("Activated", { defaultValue: "Activated" })}
              </span>
            </div>
          )}
          <div className="text-xl text-main mb-4 font-bold">
            {t("payments", { defaultValue: "Payments" })}
          </div>
          <div className="flex flex-col gap-4">
            {(list || []).map((item, idx) => (
              <div key={idx} className="border border-main px-4 py-4">
                <div>
                  <span className="font-bold mr-2">
                    {t("expired-date", { defaultValue: "Expired date" })}:
                  </span>
                  {
                    new Date(item.subscriptionExpired)
                      .toISOString()
                      .split("T")[0]
                  }
                </div>
                <div>
                  <span className="font-bold mr-2">
                    {t("activated-date", { defaultValue: "Activated date" })}:
                  </span>
                  {new Date(item.createdAt).toISOString().split("T")[0]}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </Layout>
  );
};
