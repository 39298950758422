import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Layout } from "components/common/Layout";
import { usePlatforms } from "hooks/dev/usePlatforms";
import { Trash, Logout, Hand, PlatformIcon, Globe } from "components/icons";
import { Button, Container, Modal } from "components/common";
import { Link } from "react-router-dom";
import { LocaleModal } from "components/Modal/LocaleModal";
import { useLng, useUser } from "hooks/dev";

export const Settings = () => {
  const { t } = useTranslation();
  const { language } = useLng();
  const { platforms } = usePlatforms({ checkAuth: false });
  const { userInfo } = useUser();
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(null);

  const onRemove = useCallback(
    (info) => {
      setIsOpenRemoveModal(info);
    },
    [setIsOpenRemoveModal]
  );

  const onClose = useCallback(() => {
    setIsOpenRemoveModal(null);
  }, [setIsOpenRemoveModal]);

  return (
    <Layout>
      <Container>
        <div className="pt-[10px] w-full h-full flex flex-col">
          <div className="text-3xl text-main mb-4 font-bold">
            {t("settings", { defaultValue: "Settings" })}
          </div>
          <div className="flex flex-col border border-main w-full p-[10px] md:p-[20px] mb-4">
            <div className="text-xl text-main mb-4 font-bold">
              {t("account-info", { defaultValue: "Account Info" })}
            </div>
            <div>
              <b>{t("email", { defaultValue: "Email" })}:</b> {userInfo.email}
            </div>
          </div>
          <div className="flex flex-col border border-main w-full p-[10px] md:p-[20px] mb-10">
            <div className="text-xl text-main mb-4 font-bold">
              {t("platforms", { defaultValue: "Platforms" })}
            </div>
            <div className="flex flex-col gap-4">
              {Object.keys(platforms || {}).map((platform, platformIdx) => {
                if ((platforms[platform]?.users || []).length === 0)
                  return null;
                return (
                  <div key={platformIdx}>
                    <div className="flex items-center gap-2 mb-2">
                      <PlatformIcon
                        platform={platform}
                        className="w-8 h-8 rounded-full"
                      />
                      <div className="text-xl font-medium">{platform}</div>
                    </div>
                    {(platforms[platform]?.users || []).map((user, userIdx) => {
                      return (
                        <div key={userIdx} className="flex items-center pl-10">
                          {user.usernameImage ? (
                            <img
                              src={
                                /^(https?:\/\/)/.test(user.usernameImage)
                                  ? user.usernameImage
                                  : `/uploads/platform-images/${user.usernameImage}`
                              }
                              className="w-8 h-8 rounded-full"
                            />
                          ) : (
                            <div className="w-8 h-8 bg-green-500 rounded-full flex justify-center items-center text-white text-xl">
                              {user.username?.charAt(0)}
                            </div>
                          )}
                          <div className="text-lg ml-2 mr-4">
                            {user.username}
                          </div>
                          <div
                            className="border border-main rounded-full p-1 cursor-pointer group hover:bg-main"
                            onClick={() =>
                              onRemove({
                                callback: () =>
                                  platforms[platform].onRemove(user.id),
                                user,
                              })
                            }
                          >
                            <Trash className="w-4 h-4 group-hover:text-third" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="lg:hidden mt-auto flex flex-col gap-4 pb-6">
            <LocaleModal>
              <div className="text-xl font-semibold text-main flex gap-2 items-center cursor-pointer">
                <Globe className="w-4 h-4" /> {language}
              </div>
            </LocaleModal>
            <a
              href="mailto:taislygroup@gmail.com"
              className="text-xl font-semibold text-main flex gap-2 items-center cursor-pointer"
            >
              <Hand className="w-4 h-4" />
              <span>{t("help", { defaultValue: "Help" })}</span>
            </a>
            <Link
              to="/logout"
              className="text-xl font-semibold text-main flex gap-2 items-center cursor-pointer"
            >
              <Logout className="w-4 h-4" />
              <span>{t("logout", { defaultValue: "Logout" })}</span>
            </Link>
          </div>
        </div>
      </Container>
      <Modal
        isOpen={Boolean(isOpenRemoveModal)}
        onClose={onClose}
        title={`Are you sure you want remove ${isOpenRemoveModal?.user?.platform} ${isOpenRemoveModal?.user?.username} connection?`}
      >
        <div className="grid grid-cols-2 gap-10 mt-10">
          <Button
            onClick={() => {
              isOpenRemoveModal?.callback();
              onClose();
            }}
          >
            {t("yes", { defaultValue: "Yes" })}
          </Button>
          <Button onClick={onClose}>{t("no", { defaultValue: "No" })}</Button>
        </div>
      </Modal>
    </Layout>
  );
};
