import { Button } from "components/common";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center">
      <div className="text-3xl font-bold">
        {t("page-not-found", { defaultValue: "Page Not Found" })}
      </div>
      <Link to="/">
        <Button className="mt-6">
          {t("back-home", { defaultValue: "Back home" })}
        </Button>
      </Link>
    </div>
  );
};
