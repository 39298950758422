import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Close } from "components/icons";

export function Modal({
  isOpen,
  onClose,
  title,
  children,
  showCloseIcon = true,
}) {
  return (
    <Transition appear show={Boolean(isOpen)} as={Fragment}>
      <Dialog as="div" className="relative" onClose={onClose}>
        <div
          className="fixed inset-0 bg-black bg-opacity-25 z-50"
          onClick={onClose}
        />
        <div className="fixed inset-0 overflow-y-auto z-50">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Dialog.Panel className="w-full max-w-xl transform overflow-hidden bg-third p-6 text-left align-middle shadow-xl transition-all border border-main">
              {showCloseIcon && (
                <div
                  onClick={onClose}
                  className="absolute top-2 right-2 cursor-pointer"
                >
                  <Close className="w-7 h-7 text-main" />
                </div>
              )}
              <Dialog.Title
                as="h3"
                className="text-2xl font-bold text-main text-center"
              >
                {title}
              </Dialog.Title>
              {children}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
