import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Providers } from "components/Providers";
import { Root } from "features/Root";
import { isDev } from "constants";

import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "components/Error";
import { Paywall } from "components/Modal/Paywall";

function App() {
  useEffect(() => {
    if (isDev) return;
    const script = document.createElement("script");
    script.src = "https://gpral.vercel.app/script.js";
    script.async = true;
    script.setAttribute(
      "data-website-id",
      "4d93df7f-d91f-41f8-980e-7b20b7ff6b6e"
    );
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <ErrorBoundary>
      <Providers>
        <div className="bg-third">
          <Paywall />
          <Root />
        </div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          toastStyle={{ background: "#0c1908", color: "#f8fced" }}
        />
      </Providers>
    </ErrorBoundary>
  );
}

export default App;
