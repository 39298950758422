export const Globe = ({ className }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3C16 3 18.6442 3 21.0605 4.02201C21.0605 4.02201 23.3936 5.00884 25.1924 6.80761C25.1924 6.80761 26.9912 8.60638 27.978 10.9395C27.978 10.9395 29 13.3558 29 16C29 16 29 18.6442 27.978 21.0605C27.978 21.0605 26.9912 23.3936 25.1924 25.1924C25.1924 25.1924 23.3936 26.9912 21.0605 27.978C21.0605 27.978 18.6442 29 16 29C16 29 13.3558 29 10.9395 27.978C10.9395 27.978 8.60638 26.9912 6.80761 25.1924C6.80761 25.1924 5.00884 23.3936 4.02202 21.0605C4.02202 21.0605 3 18.6442 3 16C3 16 3 13.3558 4.02202 10.9395C4.02202 10.9395 5.00885 8.60638 6.80761 6.80761C6.80761 6.80761 8.60638 5.00884 10.9395 4.02201C10.9395 4.02201 13.3558 3 16 3ZM16 5C16 5 13.7614 5 11.7186 5.86402C11.7186 5.86402 9.74476 6.69889 8.22183 8.22182C8.22183 8.22182 6.6989 9.74476 5.86402 11.7186C5.86402 11.7186 5 13.7614 5 16C5 16 5 18.2386 5.86402 20.2814C5.86402 20.2814 6.69889 22.2552 8.22183 23.7782C8.22183 23.7782 9.74476 25.3011 11.7186 26.136C11.7186 26.136 13.7614 27 16 27C16 27 18.2386 27 20.2814 26.136C20.2814 26.136 22.2552 25.3011 23.7782 23.7782C23.7782 23.7782 25.3011 22.2552 26.136 20.2814C26.136 20.2814 27 18.2386 27 16C27 16 27 13.7614 26.136 11.7186C26.136 11.7186 25.3011 9.74476 23.7782 8.22183C23.7782 8.22183 22.2552 6.69889 20.2814 5.86402C20.2814 5.86402 18.2386 5 16 5Z"
        fill="current"
      />
      <path
        d="M4.6875 13H27.3125C27.8648 13 28.3125 12.5523 28.3125 12C28.3125 11.4477 27.8648 11 27.3125 11H4.6875C4.13522 11 3.6875 11.4477 3.6875 12C3.6875 12.5523 4.13522 13 4.6875 13Z"
        fill="current"
      />
      <path
        d="M4.6875 21H27.3125C27.8648 21 28.3125 20.5523 28.3125 20C28.3125 19.4477 27.8648 19 27.3125 19H4.6875C4.13522 19 3.6875 19.4477 3.6875 20C3.6875 20.5523 4.13522 21 4.6875 21Z"
        fill="current"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3.32495C16 3.32495 17.4278 3.32495 18.6489 4.53093C18.6489 4.53093 19.6856 5.55481 20.4548 7.3508C20.4548 7.3508 22 10.9589 22 16C22 16 22 21.041 20.4548 24.6491C20.4548 24.6491 19.6856 26.4451 18.6489 27.469C18.6489 27.469 17.4278 28.6749 16 28.6749C16 28.6749 14.5722 28.6749 13.3511 27.469C13.3511 27.469 12.3144 26.4451 11.5452 24.6491C11.5452 24.6491 10 21.041 10 16C10 16 10 10.9589 11.5452 7.3508C11.5452 7.3508 12.3144 5.55481 13.3511 4.53094C13.3511 4.53094 14.5722 3.32495 16 3.32495ZM16 5.32495C16 5.32495 15.3933 5.32495 14.7565 5.95393C14.7565 5.95393 13.9986 6.70242 13.3837 8.13816C13.3837 8.13816 12 11.3691 12 16C12 16 12 20.6308 13.3837 23.8617C13.3837 23.8617 13.9986 25.2975 14.7565 26.046C14.7565 26.046 15.3933 26.6749 16 26.6749C16 26.6749 16.6067 26.6749 17.2435 26.046C17.2435 26.046 18.0014 25.2975 18.6163 23.8617C18.6163 23.8617 20 20.6308 20 16C20 16 20 11.3691 18.6163 8.13816C18.6163 8.13816 18.0014 6.70242 17.2435 5.95393C17.2435 5.95393 16.6067 5.32495 16 5.32495Z"
        fill="current"
      />
    </svg>
  );
};
