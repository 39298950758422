export const Logout = ({ className }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M24 11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H24C24.5523 13 25 12.5523 25 12C25 11.4477 24.5523 11 24 11Z"
        fill="current"
      />
      <path
        d="M14.7067 3.70748C14.8943 3.51995 15 3.26522 15 3C15 2.73478 14.8946 2.48043 14.7071 2.29289L14.6985 2.28436C14.5117 2.1024 14.2607 2 14 2C13.7348 2 13.4804 2.10536 13.2929 2.29289L4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071L13.2929 21.7071C13.4804 21.8946 13.7348 22 14 22C14.2652 22 14.5196 21.8946 14.7071 21.7071C14.8946 21.5196 15 21.2652 15 21C15 20.7348 14.8946 20.4804 14.7071 20.2929L6.41421 12L14.7067 3.70748Z"
        fill="current"
      />
      <path
        d="M0 1V23C0 23.5523 0.447715 24 1 24C1.55228 24 2 23.5523 2 23V1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1Z"
        fill="current"
      />
    </svg>
  );
};
