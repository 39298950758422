export const Container = ({ children, className, id }) => {
  return (
    <div
      id={id}
      className={["container mx-auto px-4 sm:px-6 lg:px-8 pb-4", className]
        .filter(Boolean)
        .join(" ")}
    >
      {children}
    </div>
  );
};
