export const Trash = ({ className }) => {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M23 4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H23C23.5523 6 24 5.55228 24 5C24 4.44772 23.5523 4 23 4Z"
        fill="current"
      />
      <path
        d="M8 11V19C8 19.5523 8.44771 20 9 20C9.55228 20 10 19.5523 10 19V11C10 10.4477 9.55228 10 9 10C8.44771 10 8 10.4477 8 11Z"
        fill="current"
      />
      <path
        d="M14 11V19C14 19.5523 14.4477 20 15 20C15.5523 20 16 19.5523 16 19V11C16 10.4477 15.5523 10 15 10C14.4477 10 14 10.4477 14 11Z"
        fill="current"
      />
      <path
        d="M4 24V5C4 4.44772 3.55228 4 3 4C2.44772 4 2 4.44772 2 5V24C2 24.8284 2.58579 25.4142 2.58579 25.4142C3.17157 26 4 26 4 26H20C20.8284 26 21.4142 25.4142 21.4142 25.4142C22 24.8284 22 24 22 24V5C22 4.44772 21.5523 4 21 4C20.4477 4 20 4.44772 20 5V24H4Z"
        fill="current"
      />
      <path
        d="M6.87868 0.87868C6 1.75736 6 3 6 3V5C6 5.55228 6.44772 6 7 6C7.55228 6 8 5.55228 8 5V3C8 2.58579 8.29289 2.29289 8.29289 2.29289C8.58579 2 9 2 9 2H15C15.4142 2 15.7071 2.29289 15.7071 2.29289C16 2.58579 16 3 16 3V5C16 5.55228 16.4477 6 17 6C17.5523 6 18 5.55228 18 5V3C18 1.75736 17.1213 0.87868 17.1213 0.87868C16.2426 0 15 0 15 0H9C7.75736 0 6.87868 0.87868 6.87868 0.87868Z"
        fill="current"
      />
    </svg>
  );
};
