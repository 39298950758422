export const Check = ({ className }) => {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M1.70748 7.29327L1.70711 7.29289C1.51957 7.10536 1.26522 7 1 7C0.734784 7 0.48043 7.10536 0.292893 7.29289C0.105357 7.48043 0 7.73478 0 8C5.96046e-08 8.01602 0.000384986 8.03204 0.00115448 8.04804C0.0130965 8.29635 0.117104 8.53132 0.292893 8.70711L0.293268 8.70748L7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L22.7071 1.70711C22.8946 1.51957 23 1.26522 23 1C23 0.734784 22.8946 0.48043 22.7071 0.292894C22.5196 0.105357 22.2652 0 22 0C21.7348 0 21.4804 0.105357 21.2929 0.292894L8 13.5858L1.70748 7.29327Z"
        fill="current"
      />
    </svg>
  );
};
