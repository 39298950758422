export const Input = ({
  label,
  value,
  onChange,
  id,
  placeholder,
  type = "text",
  maxLength = 1000,
}) => {
  return (
    <div className="relative">
      <label
        className="absolute font-semibold text-main break-words cursor-default pointer-events-none text-xs pl-[12px] pt-[6px] z-10"
        forhtml={id}
        id={`${id}-label`}
      >
        {label}
      </label>
      <div className="relative">
        <input
          maxLength={maxLength}
          placeholder={placeholder}
          className="appearance-none resize-none box-border text-[18px] w-full text-black block text-left px-[12px] pt-[18px] border-2 border-line bg-white transition-border-color duration-100 ease-in-out min-h-[60px] hover:border-main focus:outline-none"
          id={id}
          type={type}
          aria-invalid="false"
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
  );
};
