import { useTranslation } from "react-i18next";
import { PlatformIcon, Plus, Check } from "../icons";

export const Platform = (props) => {
  const { t } = useTranslation();
  const {
    id,
    status,
    disabled,
    platform,
    onAuth,
    isLoading,
    username,
    usernameId,
    usernameImage,
    selectedPlatforms,
    onChangeSelectedPlatforms,
  } = props;

  // console.log("onAuth platform", platform, onAuth);

  const isSelected = Boolean(selectedPlatforms?.[platform]?.[id]);

  if (isLoading) {
    return (
      <div className="relative w-[50px] h-[50px] min-w-[50px] min-h-[50px] md:w-[60px] md:h-[60px] rounded-full cursor-not-allowed border-2 border-gray-200 bg-third flex justify-center items-center">
        <div className="w-8 h-8 rounded-full animate-spin border-8 border-solid border-gray-500 border-t-transparent" />
        <PlatformIcon
          platform={platform}
          className="absolute -bottom-2 -right-2 w-6 h-6 md:w-8 md:h-8 rounded-full"
        />
      </div>
    );
  }

  if (disabled)
    return (
      <div className="relative w-[50px] h-[50px] min-w-[50px] min-h-[50px] md:w-[60px] md:h-[60px] rounded-full cursor-not-allowed border-2 border-gray-200 bg-gray-200 flex justify-center items-center">
        <div className="text-gray-500 text-sm">
          {t("soon", { defaultValue: "Soon" })}
        </div>
        <PlatformIcon
          platform={platform}
          className="absolute -bottom-2 -right-2 w-6 h-6 md:w-8 md:h-8 rounded-full"
        />
      </div>
    );

  if (status === "CONNECTED")
    return (
      <div
        onClick={() => onChangeSelectedPlatforms(platform, id)}
        className={[
          "relative w-[50px] h-[50px] md:w-[60px] min-w-[50px] min-h-[50px] md:h-[60px] rounded-full border-2 flex justify-center items-center cursor-pointer focus:outline-none",
          isSelected ? "border-gray-700" : "border-gray-200",
        ]
          .filter(Boolean)
          .join(" ")}
      >
        {isSelected && (
          <Check className="absolute -top-2 -right-2 w-6 h-6 md:w-8 md:h-8 text-white bg-gray-700 rounded-full p-1 md:p-1.5" />
        )}
        {usernameImage ? (
          <img
            className="rounded-full border-2 border-third w-full h-full"
            src={
              /^(https?:\/\/)/.test(usernameImage)
                ? usernameImage
                : `/uploads/platform-images/${usernameImage}`
            }
            alt={username}
          />
        ) : (
          <div className="bg-main rounded-full border-2 border-third p-2 w-full h-full flex justify-center items-center text-acent text-xl">
            {username?.charAt(0)}
          </div>
        )}
        <PlatformIcon
          platform={platform}
          className="absolute -bottom-2 -right-2 w-6 h-6 md:w-8 md:h-8 rounded-full"
        />
      </div>
    );

  if (status === "EXPIRED")
    return (
      <div
        onClick={onAuth}
        className="relative w-[50px] h-[50px] md:w-[60px] min-w-[50px] min-h-[50px] md:h-[60px] rounded-full border-2 flex justify-center items-center cursor-pointer focus:outline-none border-red-500"
      >
        <div className="absolute -top-2 -right-2 w-6 h-6 md:w-8 md:h-8 text-white bg-red-500 rounded-full p-1 md:p-1.5 flex justify-center items-center">
          !
        </div>
        {usernameImage ? (
          <img
            className="rounded-full border-2 border-third w-full h-full"
            src={
              /^(https?:\/\/)/.test(usernameImage)
                ? usernameImage
                : `/uploads/platform-images/${usernameImage}`
            }
            alt={username}
          />
        ) : (
          <div className="bg-green-500 rounded-full border-2 border-third p-2 w-full h-full flex justify-center items-center text-white text-xl">
            {username?.charAt(0)}
          </div>
        )}
        <PlatformIcon
          platform={platform}
          className="absolute -bottom-2 -right-2 w-6 h-6 md:w-8 md:h-8 rounded-full"
        />
      </div>
    );

  return (
    <div
      onClick={onAuth}
      className="relative w-[50px] h-[50px] min-w-[50px] min-h-[50px] md:w-[60px] md:h-[60px] rounded-full border-2 border-gray-200 flex justify-center items-center cursor-pointer"
    >
      <Plus className="w-5 h-5" />
      <PlatformIcon
        platform={platform}
        className="absolute -bottom-2 -right-2 w-6 h-6 md:w-8 md:h-8 rounded-full"
      />
    </div>
  );
};
