const Spinner = () => (
  <div className="w-7 h-7 rounded-full animate-spin border-4 border-solid border-main border-t-transparent" />
);

export const Loading = ({ isFull, text }) => {
  if (isFull) {
    return (
      <div className="min-h-[calc(100dvh)] w-full flex justify-center items-center">
        <div className="flex justify-center flex-col items-center gap-1">
          <div>
            <Spinner />
          </div>
          {text && <div className="text-xl text-main">{text}</div>}
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center flex-col items-center gap-1">
      <div>
        <Spinner />
      </div>
      {text && <div className="text-xl text-main">{text}</div>}
    </div>
  );
};
