export const YouTube = ({ className, size = 48 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 24 24`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "rounded-none fill-current"]
        .filter(Boolean)
        .join(" ")}
    >
      <g clipPath="url(#clip0_11_102)">
        <path
          d="M23.522 6.18541C23.3863 5.67482 23.1189 5.20883 22.7465 4.83407C22.3741 4.4593 21.9099 4.18891 21.4002 4.04996C19.5238 3.54541 12.0238 3.54541 12.0238 3.54541C12.0238 3.54541 4.5238 3.54541 2.64744 4.04996C2.13773 4.18891 1.67346 4.4593 1.30109 4.83407C0.92872 5.20883 0.661309 5.67482 0.525622 6.18541C0.0238038 8.06996 0.0238037 12 0.0238037 12C0.0238037 12 0.0238038 15.93 0.525622 17.8145C0.661309 18.3251 0.92872 18.7911 1.30109 19.1658C1.67346 19.5406 2.13773 19.811 2.64744 19.95C4.5238 20.4545 12.0238 20.4545 12.0238 20.4545C12.0238 20.4545 19.5238 20.4545 21.4002 19.95C21.9099 19.811 22.3741 19.5406 22.7465 19.1658C23.1189 18.7911 23.3863 18.3251 23.522 17.8145C24.0238 15.93 24.0238 12 24.0238 12C24.0238 12 24.0238 8.06996 23.522 6.18541Z"
          fill="#FF0302"
        />
        <path
          d="M9.56934 15.5687V8.4314L15.8421 12L9.56934 15.5687Z"
          fill="#FEFEFE"
        />
      </g>
      <defs>
        <clipPath id="clip0_11_102">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
