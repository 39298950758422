import { X } from "./X";
import { Facebook } from "./Facebook";
import { Instagram } from "./Instagram";
import { YouTube } from "./YouTube";
import { TikTok } from "./TikTok";

const PlatformIcons = {
  Instagram: Instagram,
  X: X,
  Facebook: Facebook,
  YouTube: YouTube,
  TikTok: TikTok,
};

export const PlatformIcon = ({ platform, className, size }) => {
  const Icon = PlatformIcons[platform];

  return <Icon className={className} size={size} />;
};
