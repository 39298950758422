import React, { Component } from "react";
import { Button } from "./common";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    fetch("/api/general/log", {
      method: "POST",
      body: JSON.stringify({ error: error.message, info }),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  reloadApp = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className="w-full min-h-[calc(100dvh)] flex flex-col justify-center items-center bg-third">
          <h2 className="text-main text-3xl mb-10">Something went wrong</h2>
          <Button onClick={this.reloadApp}>Back home</Button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
