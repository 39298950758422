class EventEmitter {
  eventMap = {};

  on(channel, handle) {
    if (!this.eventMap[channel]) {
      this.eventMap[channel] = [];
    }
    const idx = this.eventMap[channel].push(handle);

    return () => {
      this.eventMap[channel].splice(idx - 1, 1);
    };
  }

  emit(channel, ...rest) {
    const handlers = this.eventMap[channel] || [];
    handlers.forEach((handle) => {
      handle(...rest);
    });
  }
}

export const eventEmitter = new EventEmitter();

export const EVENTS = {
  FACEBOOK_PAGES_MODAL: "FACEBOOK_PAGES_MODAL",
  INSTAGRAM_ACCOUNTS_MODAL: "INSTAGRAM_ACCOUNTS_MODAL",
};
