import { useQuery } from "@tanstack/react-query";
import { useX } from "hooks/auth/useX";
import { useYouTube } from "hooks/auth/useYouTube";
import { useFacebook } from "hooks/auth/useFacebook";
import { useTikTok } from "hooks/auth/useTikTok";
import { useCallback, useEffect, useMemo, useState } from "react";

const queryFn = async () =>
  fetch("/api/private/platform/platforms")
    .then((res) => res.json())
    .then((res) => {
      if (res.success) {
        return res.data;
      }

      return {};
    });

const config = [
  {
    platform: "X",
    title: "X",
  },
  {
    platform: "YouTube",
    title: "YT",
  },
  {
    platform: "Facebook",
    title: "FB",
  },
  {
    platform: "Instagram",
    title: "IG",
  },
  {
    platform: "TikTok",
    title: "TT",
  },
];

export const usePlatforms = ({ checkAuth = false }) => {
  const {
    data,
    isLoading,
    refetch: refetchPlatforms,
  } = useQuery(["GET_USER_PLATFORMS"], queryFn, { staleTime: Infinity });
  const {
    onRemove: xOnRemove,
    onAuth: xOnAuth,
    xIsLoading,
  } = useX({
    onSuccess: refetchPlatforms,
    checkAuth,
  });
  const {
    onRemove: youtubeOnRemove,
    onAuth: youtubeOnAuth,
    youtubeIsLoading,
  } = useYouTube({
    onSuccess: refetchPlatforms,
    checkAuth,
  });
  const {
    facebookAuth,
    removeFacebook,
    instagramAuth,
    removeInstagram,
    facebookIsLoading,
    instagramIsLoading,
  } = useFacebook({
    onSuccess: refetchPlatforms,
    checkAuth,
  });
  const {
    onRemove: tiktokOnRemove,
    onAuth: tiktokOnAuth,
    tiktokIsLoading,
  } = useTikTok({
    onSuccess: refetchPlatforms,
    checkAuth,
  });

  const getAuthFnByPlatform = useMemo(
    () => ({
      X: xOnAuth,
      YouTube: youtubeOnAuth,
      Facebook: facebookAuth,
      Instagram: instagramAuth,
      TikTok: tiktokOnAuth,
    }),
    [xOnAuth, youtubeOnAuth, facebookAuth, instagramAuth, tiktokOnAuth]
  );

  const getRemoveFnByPlatform = useMemo(
    () => ({
      X: xOnRemove,
      YouTube: youtubeOnRemove,
      Facebook: removeFacebook,
      Instagram: removeInstagram,
      TikTok: tiktokOnRemove,
    }),
    [
      xOnRemove,
      youtubeOnRemove,
      removeFacebook,
      removeInstagram,
      tiktokOnRemove,
    ]
  );

  const getAuthIsLoadingByPlatform = useMemo(
    () => ({
      X: xIsLoading,
      YouTube: youtubeIsLoading,
      Instagram: instagramIsLoading,
      Facebook: facebookIsLoading,
      TikTok: tiktokIsLoading,
    }),
    [
      instagramIsLoading,
      facebookIsLoading,
      xIsLoading,
      youtubeIsLoading,
      tiktokIsLoading,
    ]
  );

  const result = useMemo(() => {
    const mergedData = {};

    config.forEach(({ platform, disabled }) => {
      const platformUserData = data?.[platform] || {};

      mergedData[platform] = {
        onRemove: getRemoveFnByPlatform[platform],
        onAuth: getAuthFnByPlatform[platform],
        isLoading: getAuthIsLoadingByPlatform[platform],
        ...(disabled ? { disabled } : {}),
        platform,
        users: Object.values(platformUserData),
      };
    });

    return mergedData;
  }, [
    data,
    getAuthFnByPlatform,
    getRemoveFnByPlatform,
    getAuthIsLoadingByPlatform,
  ]);

  return { platforms: result, isLoading, refetchPlatforms };
};

export const usePlatformSelector = () => {
  const [selectedPlatforms, setSelectedPlatforms] = useState({});
  const { platforms, isLoading } = usePlatformsPure();

  const initSelectedPlatforms = useCallback(() => {
    const output = {};
    for (const platform in platforms) {
      output[platform] = {};
      platforms[platform].users.map((user) => {
        output[platform][user.id] = true;
      });
    }
    setSelectedPlatforms(output);
  }, [platforms]);

  const onChangeSelectedPlatforms = useCallback((platform, id) => {
    setSelectedPlatforms((values) => ({
      ...values,
      [platform]: {
        ...values?.[platform],
        [id]: !Boolean(values?.[platform]?.[id]),
      },
    }));
  }, []);

  useEffect(() => {
    if (isLoading) return;
    initSelectedPlatforms();
  }, [isLoading, initSelectedPlatforms]);

  return { selectedPlatforms, onChangeSelectedPlatforms };
};

export const usePlatformsPure = () => {
  const {
    data,
    isLoading,
    refetch: refetchPlatforms,
  } = useQuery(["GET_USER_PLATFORMS"], queryFn, { staleTime: Infinity });

  const result = useMemo(() => {
    const mergedData = {};

    config.forEach(({ platform, disabled }) => {
      const platformUserData = data?.[platform] || {};

      mergedData[platform] = {
        ...(disabled ? { disabled } : {}),
        platform,
        users: Object.values(platformUserData),
      };
    });

    return mergedData;
  }, [data]);

  return { platforms: result, isLoading, refetchPlatforms };
};
