import { useMemo, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation, Trans } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { Platforms } from "components/Platforms";
import VideoUploader from "components/VideoUpload";
import { Button, Textarea, Layout, Container } from "components/common";
import { usePlatformSelector } from "hooks/dev/usePlatforms";
import { useUser } from "hooks/dev";
import { LIMIT } from "constants";
import { PostSettings } from "components/PostSettings";

const fn = (formData, t, onProgress) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", "/api/private/post");

    xhr.upload.addEventListener("progress", (e) => {
      if (e.lengthComputable) {
        const percentComplete = Math.round((e.loaded / e.total) * 100);
        onProgress(percentComplete);
      }
    });

    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(JSON.parse(xhr.responseText));
      } else {
        toast(t(res.message || "be-error-default"));
        reject(xhr.statusText);
      }
    };

    xhr.onerror = () => {
      toast(t(res.message || "be-error-default"));
      reject(xhr.statusText);
    };
    xhr.send(formData);
  });
};

const DESC_LIMIT = 100;

const DEFAULT_SETTINGS = {
  TikTok: {
    comment_disabled: true,
    duet_disabled: true,
    stitch_disabled: true,
    status: "PUBLIC_TO_EVERYONE",
  },
};

export const Post = () => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const { selectedPlatforms, onChangeSelectedPlatforms } =
    usePlatformSelector();
  const [description, setDescription] = useState("");
  const [video, setVideo] = useState();
  const [preview, setPreview] = useState();
  const [previewTime, setPreviewTime] = useState(0);
  const { userInfo, refetch: refetchUserInfo } = useUser();
  const navigate = useNavigate();
  const [settings, setSettings] = useState(DEFAULT_SETTINGS);

  const onChangeDescription = (value) => {
    const inputValue = value;
    const limitedValue = inputValue.slice(0, DESC_LIMIT);
    setDescription(limitedValue);
  };

  const isLimit = useMemo(
    () => !userInfo.activated && LIMIT <= userInfo.totalPosts,
    [userInfo]
  );

  const { mutate: onPost, isLoading } = useMutation({
    mutationFn: (formData) => fn(formData, t, setProgress),
    onSuccess: () => {
      navigate("/");
      refetchUserInfo();
    },
  });

  const onSubmit = () => {
    const platforms = [];
    Object.keys(selectedPlatforms).forEach((platform) => {
      const platformUserIdMap = selectedPlatforms[platform];
      Object.keys(platformUserIdMap).forEach((platformId) => {
        if (selectedPlatforms[platform][platformId]) {
          platforms.push(platformId);
        }
      });
    });
    if (!platforms.length) {
      toast.error(
        t("fe-error-select-platforms", {
          defaultValue: "Please select platforms",
        })
      );
      return;
    }
    if (!video) {
      toast.error(
        t("fe-error-select-video", {
          defaultValue: "Please select video",
        })
      );
      return;
    }
    const formData = new FormData();
    formData.append("video", video);
    formData.append("previewTime", Math.floor(previewTime * 1000));
    formData.append("description", description);
    formData.append("settings", JSON.stringify(settings));
    formData.append("platforms", JSON.stringify(platforms));
    onPost(formData);
  };

  return (
    <Layout>
      <Container className="pb-6">
        <div className="pt-[10px] w-full">
          <div className="text-3xl text-main mb-4 font-bold">
            {t("post", { defaultValue: "Post" })}
          </div>
          <div className="flex flex-col md:flex-row w-full gap-6">
            <VideoUploader
              video={video}
              setVideo={setVideo}
              preview={preview}
              setPreview={setPreview}
              time={previewTime}
              setTime={setPreviewTime}
            />
            <div className="flex flex-col gap-6 pt-1 w-full">
              <Platforms
                selectedPlatforms={selectedPlatforms}
                onChangeSelectedPlatforms={onChangeSelectedPlatforms}
              />
              <Textarea
                limit={DESC_LIMIT}
                className="w-full md:w-[400px]"
                value={description}
                onChange={onChangeDescription}
                label={t("description", { defaultValue: "Description" })}
                id="description"
              />
              {/*<PostSettings
                selectedPlatforms={selectedPlatforms}
                setSettings={setSettings}
                settings={settings}
              /> */}
            </div>
          </div>
          <div className="flex flex-col items-center md:w-fit">
            <Button
              disabled={isLoading || isLimit}
              isLoading={isLoading}
              className="w-full md:min-w-[200px] mt-6"
              onClick={onSubmit}
            >
              {progress
                ? `${progress}%`
                : t("post-video", { defaultValue: "Post Video" })}
            </Button>
            {!userInfo.activated && (
              <div className="text-main text-sm mt-1">
                {userInfo.totalPosts >= LIMIT ? (
                  <Trans
                    i18nKey="reached-limit-post"
                    defaults="You reached the limit. <a>Subscribe</a> for unlimit"
                    components={{
                      a: <Link to="/subscription" className="underline" />,
                      a2: (
                        <a
                          href="https://taisly.com/privacy-policy"
                          className="underline text-main"
                        />
                      ),
                    }}
                  />
                ) : (
                  t("limit-video-post", {
                    defaultValue: `Limit: {{cur}}/{{max}}`,
                    cur: userInfo.totalPosts,
                    max: LIMIT,
                  })
                )}
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};
