import { useUser } from "hooks/dev";
import { Login } from "features/Login";
import { Loading } from "components/common";
import { useTranslation } from "react-i18next";
import { Post } from "./Dashboard/Post";
import { Settings } from "./Dashboard/Settings";
import { History } from "./Dashboard/History";
import { Logout } from "./Dashboard/Logout";
import { Subscription } from "./Dashboard/Subscription";

import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useOutlet,
  Navigate,
} from "react-router-dom";
import { NotFound } from "./404";

const PrivateRoute = () => {
  const { t } = useTranslation();
  const { isLoading: isLoadingUser, isAuth } = useUser();

  const outlet = useOutlet();

  if (isLoadingUser)
    return <Loading isFull text={t("loading", { defaultValue: "Loading" })} />;
  if (!isAuth) return <Navigate to="/login" />;
  return outlet;
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<PrivateRoute />}>
        <Route path="/" element={<History />} />
        <Route path="post" element={<Post />} />
        <Route path="subscription" element={<Subscription />} />
        <Route path="settings" element={<Settings />} />
        <Route path="logout" element={<Logout />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </>
  )
);

export const Root = () => {
  return <RouterProvider router={router} />;
};
