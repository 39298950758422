export const ListCheck = ({ className }) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M12 12H23C23.5523 12 24 11.5523 24 11C24 10.4477 23.5523 10 23 10H12C11.4477 10 11 10.4477 11 11C11 11.5523 11.4477 12 12 12Z"
        fill="current"
      />
      <path
        d="M12 4H23C23.5523 4 24 3.55228 24 3C24 2.44772 23.5523 2 23 2H12C11.4477 2 11 2.44772 11 3C11 3.55228 11.4477 4 12 4Z"
        fill="current"
      />
      <path
        d="M12 20H23C23.5523 20 24 19.5523 24 19C24 18.4477 23.5523 18 23 18H12C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z"
        fill="current"
      />
      <path
        d="M8.17764 1.73539C8.18755 1.72625 8.19757 1.71664 8.20711 1.70711C8.38475 1.52946 8.48902 1.29147 8.49918 1.04045L8.49924 1.03897C8.49975 1.02598 8.5 1.01299 8.5 1C8.5 0.748775 8.40544 0.506754 8.23513 0.322072C8.22599 0.312158 8.21664 0.302429 8.20711 0.292893C8.02946 0.115251 7.79147 0.0109795 7.54045 0.000818253L7.53897 0.000759542C7.52598 0.000253201 7.51299 0 7.5 0C7.24878 -5.96046e-08 7.00675 0.0945594 6.82207 0.264872L3.16348 3.63879L1.67898 2.26585C1.49418 2.09493 1.25172 2 0.999993 2C0.986991 2 0.973988 2.00025 0.960991 2.00076C0.695976 2.01111 0.445926 2.12631 0.265848 2.32102C0.0949329 2.50582 0 2.74828 0 3C5.96046e-08 3.01301 0.000253856 3.02601 0.000761151 3.03901C0.011107 3.30402 0.126306 3.55407 0.321015 3.73415L2.48352 5.73415C2.86637 6.08824 3.45706 6.08867 3.84043 5.73513L8.17764 1.73539Z"
        fill="current"
      />
      <path
        d="M8.17764 9.7354C8.18755 9.72625 8.19757 9.71664 8.20711 9.70711C8.38475 9.52946 8.48902 9.29147 8.49918 9.04045L8.49924 9.03897C8.49975 9.02598 8.5 9.01299 8.5 9C8.5 8.74878 8.40544 8.50675 8.23513 8.32207C8.22599 8.31216 8.21664 8.30243 8.20711 8.29289C8.02946 8.11525 7.79147 8.01098 7.54045 8.00082L7.53897 8.00076C7.52598 8.00025 7.51299 8 7.5 8C7.24878 8 7.00675 8.09456 6.82207 8.26487L3.16348 11.6388L1.67898 10.2658C1.49418 10.0949 1.25172 10 0.999993 10C0.986991 10 0.973988 10.0003 0.960991 10.0008C0.695976 10.0111 0.445926 10.1263 0.265848 10.321C0.0949329 10.5058 0 10.7483 0 11C5.96046e-08 11.013 0.000253856 11.026 0.000761151 11.039C0.011107 11.304 0.126306 11.5541 0.321015 11.7342L2.48352 13.7342C2.86637 14.0882 3.45706 14.0887 3.84043 13.7351L8.17764 9.7354Z"
        fill="current"
      />
      <path
        d="M8.17764 17.7354C8.18755 17.7263 8.19757 17.7166 8.20711 17.7071C8.38475 17.5295 8.48902 17.2915 8.49918 17.0404L8.49924 17.039C8.49975 17.026 8.5 17.013 8.5 17C8.5 16.7488 8.40544 16.5068 8.23513 16.3221C8.22599 16.3122 8.21664 16.3024 8.20711 16.2929C8.02946 16.1152 7.79147 16.011 7.54045 16.0008L7.53897 16.0008C7.52598 16.0003 7.51299 16 7.5 16C7.24878 16 7.00675 16.0946 6.82207 16.2649L3.16348 19.6388L1.67898 18.2658C1.49418 18.0949 1.25172 18 0.999993 18C0.986991 18 0.973988 18.0003 0.960991 18.0008C0.695976 18.0111 0.445926 18.1263 0.265848 18.321C0.0949329 18.5058 0 18.7483 0 19C5.96046e-08 19.013 0.000253856 19.026 0.000761151 19.039C0.011107 19.304 0.126306 19.5541 0.321015 19.7342L2.48352 21.7342C2.86637 22.0882 3.45706 22.0887 3.84043 21.7351L8.17764 17.7354Z"
        fill="current"
      />
    </svg>
  );
};
