export const CheckCircle = ({ className }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className, "fill-current"].filter(Boolean).join(" ")}
    >
      <path
        d="M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.9934 9.55422 24.6216 6.25145 22.1851 3.81491C19.7486 1.37837 16.4458 0.00660747 13 0ZM19.1875 10.725L11.8625 17.725C11.6731 17.9031 11.4225 18.0016 11.1625 18C11.0354 18.0018 10.9093 17.9785 10.7913 17.9313C10.6733 17.8841 10.5658 17.814 10.475 17.725L6.81251 14.225C6.71092 14.1364 6.62831 14.0281 6.56963 13.9067C6.51095 13.7854 6.47741 13.6534 6.47104 13.5187C6.46467 13.3841 6.48559 13.2495 6.53255 13.1231C6.57951 12.9968 6.65154 12.8812 6.7443 12.7834C6.83706 12.6856 6.94865 12.6075 7.07235 12.5539C7.19605 12.5003 7.32931 12.4723 7.46412 12.4715C7.59893 12.4707 7.7325 12.4972 7.85682 12.5493C7.98113 12.6015 8.09361 12.6783 8.18751 12.775L11.1625 15.6125L17.8125 9.275C18.0069 9.10536 18.2594 9.01745 18.5172 9.02964C18.7749 9.04184 19.018 9.1532 19.1955 9.34044C19.3731 9.52767 19.4714 9.7763 19.4699 10.0343C19.4684 10.2924 19.3672 10.5398 19.1875 10.725Z"
        fill="current"
      />
    </svg>
  );
};
