import { useState, useEffect, useCallback } from "react";
import { Trans } from "react-i18next";
import { Loading, Modal } from "components/common";
import { EVENTS, eventEmitter } from "utils";
import { useFacebook } from "hooks/auth/useFacebook";
import { META_AUTH_LINK } from "constants";

export function InstagramAuthModal({ onSuccess }) {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { addInstagram } = useFacebook({
    onSuccess: () => {
      onSuccess();
      setIsOpen(false);
      setData(null);
      setIsLoading(false);
    },
    checkAuth: false,
  });

  useEffect(() =>
    eventEmitter.on(EVENTS.INSTAGRAM_ACCOUNTS_MODAL, ({ data, metaId }) => {
      setData({ data, metaId });
      setIsOpen(true);
    })
  );

  const onSettings = useCallback(() => {
    window.location.replace(META_AUTH_LINK);
  }, []);

  const body = (
    <>
      {data?.data?.length ? (
        <div className="flex flex-col gap-4">
          {(data?.data || []).map((el) => (
            <div
              onClick={() => {
                setIsLoading(true);
                addInstagram(el.id, data.metaId);
              }}
              key={el.id}
              className="group flex items-center gap-2 p-2 hover:bg-main hover:cursor-pointer"
            >
              <img src={el.image} className="w-10 h-10 rounded-full" />
              <div className="text-xl text-main font-semibold group-hover:text-third">
                {el.name}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="max-w-[80%] mx-auto text-center my-4 text-red-600">
            <Trans
              i18nKey="ig-connect-guide"
              defaults="We couldn't find any Instagram accounts. Please check our guide <a>how to connect your Instagram account</a>"
              components={{
                a: (
                  <a
                    href="https://flockler.com/blog/connect-instagram-business-with-facebook"
                    className="underline cursor-pointer"
                    target="_blank"
                  />
                ),
              }}
            />
          </div>
        </>
      )}
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setData(null);
        setIsOpen(false);
      }}
      title="Instagram"
    >
      <div className="max-w-[80%] mx-auto text-center my-4">
        <Trans
          i18nKey="ig-connect-link"
          defaults="Here's a list of the Instagram accounts you own, select one to add it to this social group. Can't find your account? Check your <a>Instagram settings here</a>"
          components={{
            a: (
              <span className="underline cursor-pointer" onClick={onSettings} />
            ),
          }}
        />
      </div>

      {isLoading ? <Loading /> : body}
    </Modal>
  );
}
