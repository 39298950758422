import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "components/common";
import { localesMap } from "constants";

const setCookie = (locale) => {
  document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`;
};

export const LocaleModal = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = useCallback((lng) => {
    setCookie(lng);
    i18n.changeLanguage(lng);
    setIsOpen(false);
  }, []);

  return (
    <>
      <div onClick={() => setIsOpen(true)}>{children}</div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Choose language"
      >
        <div className="grid grid-cols-2 md:grid-cols-3 mt-6 text-lg gap-4 text-main">
          {Object.keys(localesMap).map((el) => (
            <div
              key={el}
              className={["py-1 px-2 cursor-pointer"].filter(Boolean).join(" ")}
              onClick={() => changeLanguage(el)}
            >
              {localesMap[el].name}
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};
