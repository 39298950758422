import React from "react";
import { initReactI18next, I18nextProvider } from "react-i18next";
import i18next from "i18next";
import Backend from "i18next-http-backend";

const getCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
};

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: getCookie("NEXT_LOCALE") || "en",
    fallbackLng: getCookie("NEXT_LOCALE") || "en",
    react: {
      bindI18n: "languageChanged",
      useSuspense: false,
    },
    debug: false,
    saveMissing: false,
    partialBundledLanguages: true,
    keySeparator: false,
    interpolation: { escapeValue: false },
    loadPath: "/locales/{{lng}}/{{ns}}.json",
    crossDomain: false,
    withCredentials: false,
  });

export const I18nProvider = ({ children }) => {
  return (
    <I18nextProvider i18n={i18next} defaultNS="app">
      {children}
    </I18nextProvider>
  );
};
