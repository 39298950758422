import { useQuery } from "@tanstack/react-query";

const queryFn = async () =>
  fetch("/api/private/user/auth").then((res) => res.json());

export const useUser = (options = {}) => {
  const { enabled } = options;
  const { data, isFetching, refetch } = useQuery(["GET_AUTH"], queryFn, {
    enabled,
    staleTime: Infinity,
  });

  const isAuth = Boolean(data?.success);

  if (!isAuth) {
    return {
      isAuth,
      isLoading: isFetching,
      refetch,
    };
  }

  return {
    isAuth: Boolean(data?.success),
    isLoading: isFetching,
    userInfo: {
      ...data?.data,
    },
    refetch,
  };
};
